
.sectionTitle {
 font-size: 32px;
 @media (max-width: 460px) {
  font-size: 24px;
 }
 line-height: 24px;
 font-weight: bold;
 &.light {
  color: #132C5E;
 }
 &.dark {
  color: #ffffff;
 }
}

.transactionItemsWrapper {
 max-height: 60vh;
 overflow: auto;
 /* Hide scrollbar for Chrome, Edge, and Safari */
 //&::-webkit-scrollbar {
 // display: none;
 //}
 ///* Hide scrollbar for IE, Edge, and Firefox */
 //-ms-overflow-style: none; /* Internet Explorer 10+ */
 //scrollbar-width: none; /* Firefox */
 /* Clip the content including the scrollbar */
 clip-path: inset(0 round 30px); /* Match the border radius */

 &.customScrollbar {
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
   width: 16px;
  }

  &.dark {
   &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
   }

   &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.75);
   }

   &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
   }
  }

  &.light {
   &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 4px;
   }

   &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
   }

   &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
   }
  }

 }
}