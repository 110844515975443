@import '../styles/variables.scss'; // Import your SCSS variables here

.currentPrice {
  p {
    padding: 0.25rem 1rem;
    color: $primary-color;
    border:1px solid $primary-color;
    border-radius: 18px;
    svg {
      color: $primary-color;;
    }
  }
  @media (max-width: 640px) { // You can adjust this breakpoint as needed
    p {
      border: none; // Remove border on smaller screens
    }
  }
}
