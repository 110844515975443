
.sectionTitle {
  font-size: 32px;
  @media (max-width: 460px) {
    font-size: 24px;
  }
  line-height: 24px;
  font-weight: bold;
  &.light {
    color: #132C5E;
  }
  &.dark {
    color: #ffffff;
  }
}

.swiperButtonNext,
.swiperButtonPrev {
  bottom: 10px; /* Move arrows to bottom */
  top: auto; /* Reset the top position */
  transform: translateY(0); /* Reset any previous translation */
}

.swiperButtonNext {
  right: 50%; /* Center horizontally */
  transform: translateX(150%); /* Adjust this value to position right arrow */
}

.swiperButtonPrev {
  left: 50%; /* Center horizontally */
  transform: translateX(-250%); /* Adjust this value to position left arrow */
}
