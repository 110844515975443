.testNetRibbon {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  background: #f44336;
  color: white;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  font-weight: bold;
  transform: rotate(-45deg);
  transform-origin: 120px 96px;
  z-index: 10;
  pointer-events: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.testNetRibbon::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  background: inherit;
}


.sliderDetailItemWrapper {
  font: inherit;

  .sliderDetailItemIconWrapper {
    &.dark {
      color: #8B91B5;
      span {
        color: #8B91B5;
      }

    }
    &.light {
      color: #8B91B5;
      span {
        color: #8B91B5;
      }
    }
    svg {
      &.dark {
        color: #8B91B5;
      }
      &.light {
        color: #8B91B5;
      }
    }
  }
  .sliderDetailTitleWrapper {
    &.dark {
      color: #ffffff;
    }
    &.light {
      color: #132C5E;
    }
  }
}