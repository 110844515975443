@import '../styles/variables.scss';

.embossedCircle {
  width: 380px;
  height: 380px;
  border-radius: 50%;
  margin: 3rem auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $progressCircleBackgroundLight;
  border: 1px solid $progressCircleBorderColorLight;
  box-shadow: $progressCircleShadow;

  // Dark theme styles
  &.dark {
    background: $progressCircleBackgroundGradientDark;
    border: 1px solid $progressCircleBorderColorDark;
    box-shadow: $progressCircleShadowDark;
  }

  .progressbar {
    --percentage: var(--value);
    --size: 280px;
    --thickness: 38%;
    animation: progress 2s 0.5s forwards;
    width: var(--size);
    aspect-ratio: 1;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    display: grid;
    place-items: center;

    // Light theme background
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: conic-gradient(
                      from 0deg,
                      $progressMainColor calc(var(--percentage) * 1%),
                      $progressSecondColor calc(var(--percentage) * 1%)
      );
      mask: radial-gradient(white var(--thickness), transparent 0);
      -webkit-mask: radial-gradient(#0000 var(--thickness), #000 0);
    }

    // Dark theme background
    &.dark::before {
      background: conic-gradient(
                      from 0deg,
                      $progressMainColorDark calc(var(--percentage) * 1%),
                      $progressSecondColorDark calc(var(--percentage) * 1%)
      );
    }
  }
}

@keyframes progress {
  0% {
    --percentage: 0;
  }
  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

/* Style for the progress text */
.progressText {
  font-size: 2.5rem;
  font-weight: bold;
  z-index: 1;
  position: relative;

  &.light {
    color: #132C5E;
  }

  &.dark {
    color: #ffffff;
  }
}

/* Mobile adjustments */
@media (max-width: 600px) {
  .embossedCircle {
    width: 260px;
    height: 260px;
  }
  .progressbar {
    --size: 200px;
  }
  .progressText {
    font-size: 1.5rem;
  }
}
